import { createRouteMap } from "@model/routes";

export const routeMap = createRouteMap({
    base: "/lender-tool",
    errorPage: "error",
    routes: {
        // "splash": { next: "step-1" },
        "step-1": { next: "step-2" },
        "step-2": { next: "confirm", back: true },
        "confirm": { next: "eid", back: false, prefetch: ["error"] },
        "eid": { next: "prequal-offers", back: false, prefetch: ["error"] },
        "prequal-offers": { next: "thank-you", back: false },
        "prequal-report": { next: "thank-you", back: false },
        "error": { next: "thank-you", back: false },
        "prequal-error": { next: "thank-you", back: false },
        "equifax-down": { next: "thank-you", back: false },
        "eid-failure": { next: "thank-you", back: false },
        "fraud-check": { next: "thank-you", back: false },
        // "application": { next: "thank-you", back: "prequal-offers" },
        "thank-you": {},
    },
});
// routeMap.start = "prequal-offers";

export type { LenderToolRouteMapping } from "@model/routes";
