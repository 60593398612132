import { createRouteMap } from "@model/routes";

export const routeMap = createRouteMap({
    base: "/trade-tool",
    routes: {
        "step-1": { next: "step-2" },
        "step-2": { next: "report" },
        report: {},
        "kbb-error": {},
        "trade-error": { next: "step-1" },
    },
});

export type { TradeToolRouteMapping } from "@model/routes";