import _defineProperty from "/vercel/path0/common/temp/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/vercel/path0/common/temp/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var decode = decodeURIComponent;
var encode = encodeURIComponent;
export var getQueryParams = function getQueryParams(defaults) {
  var queryString = true ? typeof defaults === "string" && defaults || location.search : "";
  var defaultParams = typeof defaults === "object" ? defaults : {};
  if (!queryString) return defaultParams;
  var processed = queryString.slice(1).split("&").reduce(function (acc, keyVal) {
    var _keyVal$split = keyVal.split("="),
        _keyVal$split2 = _slicedToArray(_keyVal$split, 2),
        key = _keyVal$split2[0],
        val = _keyVal$split2[1];

    try {
      Object.assign(acc, _defineProperty({}, decode(key !== null && key !== void 0 ? key : ""), decode(val !== null && val !== void 0 ? val : "")));
    } catch (e) {// noop
    }

    return acc;
  }, {});
  return _objectSpread(_objectSpread({}, defaultParams), processed);
};
export var createQueryString = function createQueryString(obj) {
  var dropEmpty = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return Object.keys(obj).reduce(function (acc, key) {
    var val = obj[key];
    if (!val && dropEmpty || !key) return acc;

    try {
      acc.push([encode(key), val != null && encode(val)].filter(Boolean).join("="));
    } catch (e) {// noop
    }

    return acc;
  }, []).join("&");
};