import { createRouteMap } from "@model/routes";

export const routeMap = createRouteMap({
    base: "/credit-tool",
    errorPage: "error",
    routes: {
        // "splash": { next: "step-1" },
        "step-1": { next: "step-2" },
        "step-2": { next: "step-3", back: true },
        "step-3": { next: "confirm", back: true },
        "confirm": { next: "eid", back: false, prefetch: ["error"] },
        "eid": { next: "score", back: false, prefetch: ["error"] },
        "score": { next: "application", back: false },
        "score-details": { back: true },
        "error": { next: "application", back: false },
        "equifax-down": { next: "application", back: false },
        "eid-failure": { next: "application", back: false },
        "fraud-check": { next: "application", back: false },
        "application": { next: "thank-you", back: "score" },
        "thank-you": {},
    },
});

export type { CreditToolRouteMapping } from "@model/routes";